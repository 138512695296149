<template>
  <div class="dict" v-loading.fullscreen.lock="loadingDetail">
    <el-page-header
      style="margin-bottom: 1.04167vw"
      @back="goBack"
      :content="'待办任务/查看'"
    >
      <template slot="title"> <i class="el-icon-arrow-left"></i>返回 </template>
    </el-page-header>
    <el-form
      ref="ruleForm"
      style="width: 80%"
      :model="form"
      :label-width="labelWidth"
    >
      <div class="con-header-little">
        <i class="el-icon-caret-bottom" style="margin-right: 0.52083vw"></i>
        <span>基本信息</span>
      </div>
      <el-form-item
        label="分公司："
        v-if="form.taskStatus && form.taskStatus.value != 5"
      >
        <div class="onetable">
          <div class="companyName">
            {{ form.companyName }}
          </div>
          <div>
            <el-form-item label="状态：">
              <span v-if="form.taskStatus">{{ form.taskStatus.label }}</span>
            </el-form-item>
          </div>
          <div class="banliBtnBox" v-if="form.taskStatus">
            <!-- 总部-->
            <el-button
              class="banliBtn"
              @click="handleSupervise"
              type="text"
              v-if="
                (form.taskStatus.value == 0 || form.taskStatus.value == 1) &&
                role == 1 &&
                form.userId.indexOf(userId) == -1 &&
                form.isSupervise.startsWith('0') &&
                form.timeBtn >= 0
              "
              >督办</el-button
            >
            <el-button
              class="banliBtn"
              disabled
              type="text"
              v-if="
                (form.taskStatus.value == 0 || form.taskStatus.value == 1) &&
                role == 1 &&
                form.userId.indexOf(userId) == -1 &&
                form.isSupervise.startsWith('1') &&
                form.timeBtn >= 0
              "
              >已督办</el-button
            >
            <!-- 分公司 -->
            <el-button
              class="banliBtn"
              @click="handleSupervise"
              type="text"
              v-if="
                (form.taskStatus.value == 0 || form.taskStatus.value == 1) &&
                role == 2 &&
                form.userId.indexOf(userId) == -1 &&
                form.isSupervise.startsWith('00') &&
                form.timeBtn >= 0
              "
              >督办</el-button
            >
            <el-button
              class="banliBtn"
              disabled
              type="text"
              v-if="
                (form.taskStatus.value == 0 || form.taskStatus.value == 1) &&
                role == 2 &&
                form.userId.indexOf(userId) == -1 &&
                !form.isSupervise.startsWith('00') &&
                form.timeBtn >= 0
              "
              >已督办</el-button
            >
            <!-- 园校 -->
            <el-button
              class="banliBtn"
              @click="handleSupervise"
              type="text"
              v-if="
                (form.taskStatus.value == 0 || form.taskStatus.value == 1) &&
                role == 3 &&
                form.userId.indexOf(userId) == -1 &&
                form.isSupervise == '000' &&
                form.timeBtn >= 0
              "
              >督办</el-button
            >
            <el-button
              class="banliBtn"
              disabled
              type="text"
              v-if="
                (form.taskStatus.value == 0 || form.taskStatus.value == 1) &&
                role == 3 &&
                form.userId.indexOf(userId) == -1 &&
                form.isSupervise != '000' &&
                form.timeBtn >= 0
              "
              >已督办</el-button
            >
            <!-- 1，总公司显示审批的条件 状态为2，办理人年为2，当前用户id = 发布人id；
                             2，分公司同上；
                             3，园长，状态为2，办理人为1， -->
            <el-button
              class="banliBtn"
              v-if="
                form.timeoutUserId &&
                role == 1 &&
                form.taskStatus.value == 2 &&
                form.timeoutUserType.value == 1 &&
                form.timeoutUserId.indexOf(userId) != -1
              "
              @click="goPage(1)"
              type="text"
              >审批</el-button
            >
            <el-button
              class="banliBtn"
              v-if="
                form.timeoutUserId &&
                role == 3 &&
                form.taskStatus.value == 2 &&
                form.timeoutUserType.value == 2 &&
                form.timeoutUserId.indexOf(userId) != -1
              "
              @click="goPage(1)"
              type="text"
              >审批</el-button
            >
            <!-- 园校执行人和园长才有办理 -->
            <el-button
              class="banliBtn"
              type="text"
              @click="goPage(2)"
              v-if="
                form.taskStatus.value == 0 &&
                form.userId.indexOf(userId) != -1 &&
                (role == 3 || role == 4) &&
                form.timeBtn >= 0
              "
              >办理</el-button
            >
            <!-- <el-button class="banliBtn" type="text" @click="goPage(2)" v-if="form.taskStatus.value == 1 && form.userId.indexOf(userId) != -1 && (role == 3 || role == 4)">逾期办理</el-button> -->
            <el-button
              class="banliBtn"
              type="text"
              @click="goPage(3)"
              v-if="
                form.taskStatus.value == 1 &&
                form.userId.indexOf(userId) != -1 &&
                (role == 3 || role == 4)
              "
              >逾期反馈</el-button
            >
            <!-- {{form.taskStatus.value}}{{form.isCustom.value}}{{form.timeBtn}} -->
            <el-button
              v-if="
                form.taskStatus.value == 0 &&
                form.isCustom.value == 1 &&
                form.timeBtn >= 0 &&
                (role == 1 || role == 2)
              "
              @click="goPage(2)"
              type="text"
              >办理</el-button
            >
          </div>
        </div>
      </el-form-item>
      <el-form-item
        label="状态："
        v-if="form.taskStatus && form.taskStatus.value == 5"
      >
        <span v-if="form.taskStatus">{{ form.taskStatus.label }}</span>
      </el-form-item>
      <el-form-item
        label="园校："
        v-if="form.taskStatus && form.taskStatus.value != 5"
      >
        {{ form.campusName }}
      </el-form-item>
      <el-form-item
        label="岗位："
        v-if="form.taskStatus && form.taskStatus.value != 5"
      >
        {{ form.jobId }}
      </el-form-item>
      <el-form-item label="姓名：">
        {{ form.userName }}
      </el-form-item>
      <el-form-item label="任务名称：" prop="">
        {{ form.itemName }}
      </el-form-item>
      <el-form-item
        label="项目要求："
        prop=""
        v-if="form.taskStatus && form.taskStatus.value != 5"
      >
        {{ form.itemRequire }}
      </el-form-item>
      <el-form-item
        label="关联资料："
        prop=""
        v-if="form.taskStatus && form.taskStatus.value != 5"
      >
        <!-- {{form.materialName}} -->
        <el-button @click="downloadFormFile(form)" type="text">{{
          form.materialName
        }}</el-button>
      </el-form-item>
      <el-form-item label="次数/总次数：" prop="">
        {{ form.presentTimes }}/{{ form.itemTimes }}
      </el-form-item>
      <el-form-item label="周期：" prop=""> {{ form.period }}天 </el-form-item>
      <el-form-item label="开始时间：" prop="">
        {{ form.startTime && form.startTime.slice(0, 10) }}
      </el-form-item>
      <el-form-item label="截止时间：" prop="">
        {{ form.endTime && form.endTime.slice(0, 10) }}
      </el-form-item>
      <el-form-item
        label="督办记录："
        class="duban"
        prop=""
        v-if="form.taskStatus && form.taskStatus.value != 5"
      >
        <el-table :data="superviseRecord" style="width: 100%">
          <el-table-column
            prop="userName"
            label="督办人"
            min-width="180"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="createTime"
            label="督办时间"
            min-width="180"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="comment"
            min-width="180"
            label="督办建议"
            align="center"
          >
          </el-table-column>
        </el-table>
      </el-form-item>
      <el-form-item
        label="操作记录："
        prop=""
        v-if="form.taskStatus && form.taskStatus.value != 5"
      >
        <el-table class="table" :data="operationRecord" style="width: 100%">
          <el-table-column
            prop="createTime"
            label="时间"
            width="160"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="operateType"
            label="操作"
            width="120"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="userName"
            label="操作人"
            width="100"
            align="center"
          >
          </el-table-column>
          <el-table-column prop="operateResult" label="操作结果" align="center">
          </el-table-column>
          <el-table-column prop="comment" label="备注" align="center">
            <template slot-scope="scope">
              <div class="handle-comment">{{ scope.row.comment }}</div>
              <div class="file-content" v-if="scope.row.attachments">
                <el-button
                  class="table-handle file"
                  @click="downloadHandeFile(item)"
                  type="text"
                  v-for="item in scope.row.attachments"
                  :key="item.id"
                  >{{ item.name }}</el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>
    </el-form>
    <!-- 督办弹窗 -->
    <el-dialog
      title="督办"
      @close="clearForm"
      :visible.sync="supervisePanel"
      width="30%"
    >
      <el-row :gutter="20">
        <el-col :span="5" style="text-align: right">督办建议</el-col>
        <el-col :span="18">
          <el-input
            type="textarea"
            placeholder="请输入内容"
            v-model="supervise.comment"
            maxlength="100"
            rows="6"
            show-word-limit
          >
          </el-input>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="supervisePanel = false">取 消</el-button>
        <el-button
          type="primary"
          :loading="loadingDB"
          :disabled="loadingDB"
          @click="superviseSbmit"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-image
      style="width: 0; height: 0"
      ref="viewImg"
      :src="viewUrl"
      :preview-src-list="viewUrlList"
    ></el-image>
    <onlinePreview
      :onlinePreviewVisible="onlinePreviewVisible"
      :type="fileAttachment.previewType"
      @closeOnlinePreviewDialog="closeOnlinePreviewDialog"
      :url="fileAttachment.url"
      :title="fileAttachment.title"
    />
  </div>
</template>
<script>
import downloadFile from "@/utils/downloadFile.js";
import onlinePreview from "@/components/onlinePreview/index.vue";
export default {
  components: { onlinePreview },
  data() {
    return {
      form: {},
      superviseRecord: [], //督办
      operationRecord: [],
      supervisePanel: false,
      supervise: {
        comment: "",
      },
      role: 0,
      userId: 0,
      loadingDetail: false,
      loadingDB: false,

      viewUrl: "", // 附件图片预览
      viewUrlList: [], // 附件图片预览list

      onlinePreviewVisible: false, //文件预览标识
      fileAttachment: {}, //预览文件对象

      labelWidth: "120px",
    };
  },
  created() {
    this.init();
  },
  mounted() {
    window.addEventListener("resize", (e) => {
      if (e.target.innerWidth < 1300) {
        this.labelWidth = "80px";
      } else {
        this.labelWidth = (120 / 1920) * e.target.innerWidth + "px";
      }
      this.$forceUpdate();
    });
    if (window.innerWidth < 1300) {
      this.labelWidth = "80px";
    } else {
      this.labelWidth = (120 / 1920) * window.innerWidth + "px";
    }
  },
  methods: {
    init() {
      this.userId = this.$store.state.userInfo.userId;
      if (this.$route.query.role) {
        this.role = this.$route.query.role;
      } else {
        if (this.$store.state.userInfo.authList[0] == "org:z") {
          this.role = 1;
        } else if (this.$store.state.userInfo.authList[0] == "org:f") {
          this.role = 2;
        } else {
          this.getUserByPostLabel();
        }
      }
      console.log(this.userId);
      this.initDetail();
    },
    goBack() {
      this.$router.go(-1);
    },
    // 判断是否是园长
    getUserByPostLabel() {
      this.$api
        .getUserByPostLabel({
          officeLabel: this.$store.state.userInfo.schoolList[0].schoolLabel,
          label: "/aFAwR0ZpbywrIEnyff6Iw==",
        })
        .then((res) => {
          if (res.data.code == "0") {
            if (res.data.data.indexOf(this.$store.state.userInfo.userId) >= 0) {
              this.role = 3;
            } else {
              this.role = 4;
            }
          } else {
            this.$message.error(res.data.data.msg);
          }
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },
    // 初始化详情
    initDetail() {
      this.loadingDetail = true;
      this.$api.getTaskDetail(this.$route.query.id).then((res) => {
        this.loadingDetail = false;
        if (res.data.code == "0") {
          this.form = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
      this.$api.getTaskDetailOperate(this.$route.query.id).then((res) => {
        if (res.data.code == "0") {
          this.operationRecord = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
      this.$api.getTaskDetailSupervise(this.$route.query.id).then((res) => {
        if (res.data.code == "0") {
          this.superviseRecord = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    goPage(type) {
      if (type == 1) {
        // 审批
        this.$router.push({
          path: "/upcomingTasksApprove",
          query: {
            id: this.$route.query.id,
          },
        });
      } else if (type == 2) {
        // 办理
        this.$router.push({
          path: "/upcomingTasksHandle",
          query: {
            id: this.$route.query.id,
          },
        });
      } else if (type == 3) {
        this.$router.push({
          path: "/upcomingTasksFeedback",
          query: {
            id: this.$route.query.id,
          },
        });
      }
    },
    //关闭预览
    closeOnlinePreviewDialog() {
      this.fileAttachment = {};
      this.onlinePreviewVisible = false;
    },
    //预览资料
    downloadFormFile(item) {
      if (item.materialId) {
        let pathType = item.materialPath
          .substr(
            item.materialPath.lastIndexOf(".") + 1,
            item.materialPath.length
          )
          .toLowerCase();
        let noDownloadIndex = [
          "mp4",
          "mov",
          "rmvb",
          "flv",
          "avi",
          "mpg",
        ].findIndex((info) => {
          return info == pathType;
        });
        let videoIndex = ["mp4", "mov", "rmvb", "flv", "avi", "mpg"].findIndex(
          (info) => {
            return info == pathType;
          }
        );
        if (noDownloadIndex === -1) {
          if (item.materialPdfPath|| (item.materialPath && pathType == "pdf")) {
            this.onlinePreviewVisible = true;
            this.$nextTick(() => {
              this.fileAttachment = {
                previewType: "pdf",
                url: item.materialPdfPath || item.materialPath,
                attachment_name: item.materialName,
                attachment_type: pathType,
                title: item.materialName,
              };
            });
          }
        } else if (videoIndex != -1) {
          this.onlinePreviewVisible = true;
          this.$nextTick(() => {
            this.fileAttachment = {
              previewType: "video",
              url: item.materialPath,
              attachment_name: item.materialName,
              attachment_type: pathType,
              title: item.materialName,
            };
          });
        } else {
          this.$message.error("暂不支持查看");
        }
      }
    },
    //预览历史评分附件
    downloadHandeFile(row) {
      let url = "/operate/attachment/downloadFile";
      let pathType = row.name
        .substr(row.name.lastIndexOf(".") + 1, row.name.length)
        .toLowerCase();
      let noDownloadIndex = [
        "png",
        "jpg",
        "jpeg",
        "gif",
        "mp4",
        "mov",
        "rmvb",
        "flv",
        "avi",
        "mpg",
      ].findIndex((info) => {
        return info == pathType;
      });
      let index = ["png", "jpg", "jpeg", "gif"].findIndex((info) => {
        return info == pathType;
      });
      let videoIndex = ["mp4", "mov", "rmvb", "flv", "avi", "mpg"].findIndex(
        (info) => {
          return info == pathType;
        }
      );
      if (noDownloadIndex === -1) {
        if (row.pdfPath || (row.path && pathType == "pdf")) {
          this.onlinePreviewVisible = true;
          this.$nextTick(() => {
            this.fileAttachment = {
              previewType: "pdf",
              url: row.pdfPath || row.path,
              attachment_name: row.name,
              attachment_type: row.type,
              title: row.name,
            };
          });
        }
      } else if (videoIndex != -1) {
        this.onlinePreviewVisible = true;
        this.$nextTick(() => {
          this.fileAttachment = {
            previewType: "video",
            url: row.path,
            attachment_name: row.name,
            attachment_type: row.type,
            title: row.name,
          };
        });
      } else if (index != -1) {
        this.viewUrl = row.path;
        this.viewUrlList = [row.path];
        this.$nextTick(() => {
          this.$refs.viewImg.showViewer = true;
        });
      } else {
        this.$message.error("暂不支持预览");
      }
    },
    clearForm() {
      this.supervise.comment = "";
    },
    handleSupervise() {
      this.supervisePanel = true;
    },
    superviseSbmit() {
      // this.supervise.comment
      this.loadingDB = true;
      this.$api
        .supervisionSave({
          comment: this.supervise.comment,
          taskDetailId: this.$route.query.id,
          superviseLevel: this.role,
        })
        .then((res) => {
          this.loadingDB = false;
          if (res.data.code == 0) {
            this.$message({
              type: "success",
              message: "保存成功",
            });
            this.supervisePanel = false;
            this.initDetail();
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((error) => {
          this.loadingDB = false;
        });
    },
  },
  watch: {
    $route() {
      // console.log(this.$route.query.id)
      this.init();
      // this.userId = this.$route.query.id
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", (e) => {
      if (e.target.innerWidth < 1300) {
        this.labelWidth = "80px";
      } else {
        this.labelWidth = (120 / 1920) * e.target.innerWidth + "px";
      }
      this.$forceUpdate();
    });
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/select_list.scss";
.dict {
  background-color: #ffffff;
  width: calc(100% - 60px);
  height: calc(100% - 64px);
  padding: 34px 30px 30px 30px;
  overflow-x: hidden;
  .tableList {
    height: calc(100% - 100px);
  }
  /deep/.el-table {
    thead tr {
      background-color: #f1f4f9;
      border-radius: 12px 12px 0px 0px;
      :first-child {
        border-radius: 12px 0px 0px 0px;
      }
      :nth-last-child(2) {
        border-radius: 0px 12px 0px 0px;
      }
    }
    th {
      background-color: #f1f4f9;
      padding: 0;
    }
  }
  .itemTime {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    background-color: #f5f7fa;
    border-color: #e4e7ed;
    color: #c0c4cc;
    text-indent: 15px;
  }
  .con-header-little {
    margin: 40px 0 30px 0;
  }

  .con-header-little span {
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    color: #3d3e4f;
  }
  .onetable {
    width: 100%;
    display: flex;
    .companyName {
      width: 20%;
    }
    :first-child {
    }
    .banliBtnBox {
      margin-left: 5px;
    }
    // :nth-child(2){
    //     width: 150px;
    //     margin-left: 15px;
    // }
  }
  .file-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .table-handle.file {
    display: block;
  }
}
.banliBtn {
  position: relative;
  z-index: 99;
}
.el-button.is-disabled,
.el-button.is-disabled:focus,
.el-button.is-disabled:hover {
  color: #c0c4cc !important;
}
</style>
